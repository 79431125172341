<template>
    <div style="background-color: white;overflow-y: auto;">


        <div style="padding: 20px;box-sizing: border-box;margin: 0 auto;width: 80%;margin-top: 0px;max-width: 1340px;">
            <div style="width: 100%;">
                <div>Order Information</div>
                <div class="pay-info">
                    <div style="display: flex;justify-content: space-between;">
                        <p>Item</p>
                        <p style="font-weight: bold;">IPsyE Subscription</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Original Price</p>
                        <p style="font-weight: bold;">{{ applicationInfo.standard_price }} {{ applicationInfo.money_type
                            }}</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Discount</p>
                        <p style="font-weight: bold;">{{ applicationInfo.standard_price - applicationInfo.total_price }}
                            {{ applicationInfo.money_type }}</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Price after Discount</p>
                        <p style="font-weight: bold;">{{ applicationInfo.total_price }} {{ applicationInfo.money_type }}
                        </p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Payment Service Fee </p>
                        <p style="font-weight: bold;">{{ applicationInfo.service_fee }} {{ applicationInfo.money_type }}
                        </p>
                    </div>

                    <div style="display: flex;justify-content: space-between;margin-top: 40px;">
                        <p style="font-weight: bold;">Total Order Amount</p>
                        <p style="font-weight: bold;color: #D60034 ;">{{ amount }} {{ applicationInfo.money_type }}</p>
                    </div>
                </div>
                <p style="color: #666;margin-top: 10px;margin-left: 30px;">*The payment service fee is charged by the
                    payment platform at a rate of 3.9% plus 0.43 {{ applicationInfo.money_type }} per transaction.</p>

                <p style="color: #333;font-size: 18px;font-weight: bold;margin-top: 40px;">Terms and Conditions</p>
                <div class="terms">
                    Courses and cases offered on our platform are virtual products. As such, once payment is completed,
                    we do not offer refunds under any circumstances.
                    <br>
                    <br>
                    Policy Details:
                    <br>
                    No Refunds: Due to the nature of our digital products, all sales are final. Once you have paid, you
                    will not be able to request a refund or cancellation.
                    <br>
                    <br>
                    Access to Content: Upon successful payment, you will receive immediate access to the learning
                    materials. This access is considered the full delivery of our service.
                </div>

                <div class="terms"
                    style="margin-top: 20px;background-color: white;display: flex;align-items: center;justify-content: center;">
                    <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                        <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                            :src="require('../assets/unchecked.png')" />
                        <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                            :src="require('../assets/checked.png')" />
                    </div>
                    <p> I've read and agree to the terms and conditions.</p>
                </div>

                <div class="w-full" style="margin: 20px 0px;display: flex;align-items: center;justify-content: center;">
                    <div class="btn-purchase" @click="doPay">Pay</div>
                    <!-- <div class="btn-purchase" style="margin-left: 20px;" @click="verifyUP">Verify EAE Universal Pass
                    </div> -->
                </div>
                <div style="width: 100%;height: 50px;">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    checkEAEUPAccount,
    getCaseApplication
} from '../api/ipsye'
import {
    getUserId
} from '../utils/store'
export default ({
    name: 'index',
    data() {
        return {
            amount: 0,
            isAgree: false,
            application_id: this.$route.query.application_id,
            applicationInfo: {

            }
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getCaseApplication(this.application_id).then((res) => {
                this.applicationInfo = res.data.data
                this.amount = this.applicationInfo.service_fee + this.applicationInfo.total_price
                if (this.amount <= 0) {
                    this.amount = 0
                }
            })
        },
        doPay() {
            if (!this.isAgree) {
                this.$message.warning("Please read terms and agree it");
                return;
            }
            let url = this.$router.resolve({
                path: "/pay",
                query: {
                    application_id: this.application_id
                },
            });
            window.open(url.href, "_blank");
        },
        verifyUP() {
            if (!this.isAgree) {
                this.$message.warning("Please read terms and agree it");
                return;
            }
            checkEAEUPAccount(getUserId()).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.$globalMessage.$emit('refreshApplication')
                    this.$message.success('Verify success~')
                    this.$router.go(-1)
                } else {
                    this.$message.success('Verify failed~')
                }
            })
        }
    }
})
</script>
<style lang="scss" scoped>
.btn-purchase {
    min-width: 80px;
    padding: 10px 15px;
    text-align: center;
    background-color: #D60034;
    font-size: 18px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

::-webkit-scrollbar {
    display: none;
}

.btn {
    width: 160px;
}

.terms {
    margin-top: 10px;
    width: 100%;
    background-color: #F4F4F4;
    padding: 20px 30px;
    box-sizing: border-box;
    color: #666;
    font-size: 16px;
    line-height: 24px;
}

.pay-info {
    border-radius: 12px;
    background-color: #FFF8F8;
    color: #333;
    border: 1px solid #DEDEDE;
    padding: 20px 40px;
    box-sizing: border-box;
    margin-top: 30px;
}
</style>